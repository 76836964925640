import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { CalendarioTableComponent } from './calendario-table/calendario-table.component';
import { AreasTableComponent } from './areas-table/areas-table.component';
import { ListaTrabajosComponent } from './lista-trabajos/lista-trabajos.component';
import { ModalTrabajoComponent } from './modal-trabajo/modal-trabajo.component';
import { CalendarioPostersComponent } from './calendario-posters/calendario-posters.component';
import { CalendarioPlenariasComponent } from './calendario-plenarias/calendario-plenarias.component';
import { CalendarioPlenarias2Component } from './calendario-plenarias2/calendario-plenarias2.component';
import { CalendarioOralesComponent } from './calendario-orales/calendario-orales.component';
import { CalendarioDetalleComponent } from './calendario-detalle/calendario-detalle.component';
import { Lunes1Component } from './lunes1/lunes1.component';
import { Lunes2Component } from './lunes2/lunes2.component';
import { Lunes3Component } from './lunes3/lunes3.component';
import { Lunes4Component } from './lunes4/lunes4.component';
import { Lunes5Component } from './lunes5/lunes5.component';
import { Martes1Component } from './martes1/martes1.component';
import { Martes2Component } from './martes2/martes2.component';
import { Martes3Component } from './martes3/martes3.component';
import { Martes4Component } from './martes4/martes4.component';
import { Martes5Component } from './martes5/martes5.component';
import { Miercoles1Component } from './miercoles1/miercoles1.component';
import { Miercoles2Component } from './miercoles2/miercoles2.component';
import { Miercoles3Component } from './miercoles3/miercoles3.component';
import { Miercoles4Component } from './miercoles4/miercoles4.component';
import { Miercoles5Component } from './miercoles5/miercoles5.component';
import { Jueves1Component } from './jueves1/jueves1.component';
import { Jueves2Component } from './jueves2/jueves2.component';
import { Jueves3Component } from './jueves3/jueves3.component';
import { Jueves4Component } from './jueves4/jueves4.component';
import { BlocksComponent } from './blocks/blocks.component';
import { Viernes1Component } from './viernes1/viernes1.component';
import { Viernes2Component } from './viernes2/viernes2.component';
import { Viernes3Component } from './viernes3/viernes3.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      { path: 'search', component: CalendarioTableComponent, data: { depth: 1 } },
      {
        path: 'areas', component: AreasTableComponent, children: [
          { path: 'plenarias-1', component: CalendarioPlenariasComponent, data: { depth: 4 } },
          { path: 'plenarias-2', component: CalendarioPlenarias2Component, data: { depth: 5 } },
          { path: 'posters', component: CalendarioPostersComponent, data: { depth: 3 } },
          { path: 'orales/:room', component: CalendarioOralesComponent, data: { depth: 6 } },
          { path: 'oral/lunes-1', component: Lunes1Component, data: { depth: 2 } },
          { path: 'oral/lunes-2', component: Lunes2Component, data: { depth: 2 } },
          { path: 'oral/lunes-3', component: Lunes3Component, data: { depth: 2 } },
          { path: 'poster/lunes-4', component: Lunes4Component, data: { depth: 2 } },
          { path: 'plenaria/lunes-5', component: Lunes5Component, data: { depth: 2 } },
          { path: 'oral/martes-1', component: Martes1Component, data: { depth: 2 } },
          { path: 'oral/martes-2', component: Martes2Component, data: { depth: 2 } },
          { path: 'oral/martes-3', component: Martes3Component, data: { depth: 2 } },
          { path: 'poster/martes-4', component: Martes4Component, data: { depth: 2 } },
          { path: 'plenaria/martes-5', component: Martes5Component, data: { depth: 2 } },
          { path: 'oral/miercoles-1', component: Miercoles1Component, data: { depth: 2 } },
          { path: 'oral/miercoles-2', component: Miercoles2Component, data: { depth: 2 } },
          { path: 'oral/miercoles-3', component: Miercoles3Component, data: { depth: 2 } },
          { path: 'poster/miercoles-4', component: Miercoles4Component, data: { depth: 2 } },
          { path: 'plenaria/miercoles-5', component: Miercoles5Component, data: { depth: 2 } },
          { path: 'oral/jueves-1', component: Jueves1Component, data: { depth: 2 } },
          { path: 'plenaria/jueves-2', component: Jueves2Component, data: { depth: 2 } },
          { path: 'oral/jueves-3', component: Jueves3Component, data: { depth: 2 } },
          { path: 'oral/jueves-4', component: Jueves4Component, data: { depth: 2 } },
          { path: 'plenaria/jueves-5', component: BlocksComponent, data: { depth: 2 } },
          { path: 'oral/viernes-1', component: Viernes1Component, data: { depth: 2 } },
          { path: 'plenaria/viernes-2', component: Viernes2Component, data: { depth: 2 } },
          { path: 'oral/viernes-3', component: Viernes3Component, data: { depth: 2 } },
          {path:'trabajos/:area', component:ListaTrabajosComponent, data:{depth:2}}
        ],
        data: { depth: 2 }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


